import * as React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '@components/Layout'
import UI, { Box } from '@components/UI'
import { Query } from '@generated/graphql'

const Title = styled(UI.H3).attrs({
  pb: 1
})``

const Description = styled(UI.H3).attrs({
  color: 'primary'
})``

const GLink = styled(UI.GLink)``
const getPath = (absolutePath: string) =>
  absolutePath
    .match(/press.*\.md$/)[0]
    .replace(/[0-9]._/, '')
    .replace(/\..*$/, '')

const PressBox = styled(Box).attrs({
  pb: 4,
  mb: 4,
  borderStyle: 'solid',
  borderWidth: 0,
  borderTop: 0,
  borderX: 0,
  borderColor: 'gray'
})``

type ReviewProps = {
  title: string
  by: string
  path: string
}
const Review = ({ title, by, path }: ReviewProps) => (
  <Box>
    <GLink to={path}>
      <PressBox>
        <Title>{title}</Title>
        <Description>{by}</Description>
      </PressBox>
    </GLink>
  </Box>
)

const Press = ({ data }: { data: Query }) => {
  const nodes = _.orderBy(
    data.allMarkdownRemark.nodes,
    'fileAbsolutePath',
    'desc'
  )

  return (
    <Layout title="Press">
      {_.map(nodes, ({ fileAbsolutePath, frontmatter: { title, by } }) => (
        <Review title={title} by={by} path={getPath(fileAbsolutePath)} />
      ))}
    </Layout>
  )
}

export default Press

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content.press/" } }
      sort: { fields: fileAbsolutePath, order: DESC }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          title
          by
        }
      }
    }
  }
`
